import React from 'react'
import ContentLoader from 'react-content-loader'
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow } from './CustomArrow';



const Loader = ({ type, mt }) => {
    const margin = mt || ''
    const length = type == 'pkg' ? 4 : type == 'category' ? 6 : 5

    const lengthIs = Array.from({ length: length })

    const responsive = {
        desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: length,
            slidesToSlide: length
        },

        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 2,
            slidesToSlide: 2
        },
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={true}
            autoPlaySpeed={2500}
            keyBoardControl={true}
            customTransition="transform 1000ms ease-in-out"
            containerClass={`carousel-container gap-3 ${margin}`}
            itemClass="carousel-item1 gap-3"
            customLeftArrow={
                <CustomLeftArrow
                    direction="left"
                />
            }
            customRightArrow={
                <CustomLeftArrow
                    direction="right"
                />
            }
            removeArrowOnDeviceType={["desktop", "mobile"]}
            dotListClass="custom-dot-list-style"
        >
            <div>hii</div>
        </Carousel>
    )
}

const SingleLoader = () => {
    return (
       <div>jjjj</div>
    )
}

export { SingleLoader, Loader, }