import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import SignInModal from '../Pages/Auth/SignInModal';
const vendor_url = process.env.REACT_APP_VENDOR_URL;

function Footer() {
    const user1 = useSelector(state => state.data.info)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openME = () => {
        setIsModalOpen(true)
    }

    const typeIs = (!user1 && user1 == null || user1?.user?.role == 'vendor')
    const serv = user1 && user1?.user?.services

    return (
        <div className='footer bg_thm text-white'>
            <div className='sb_footer'>
                <div className='sb_footer_links mt-4 section_padding'>
                    <div className='sb_footer_links_div align-items-start'>
                        <Link to="/" className="navbar-brand bg-warning1 d-flex align-items-center justify-content-center ps-0 ms-0">
                            <img src="/icon1.png" alt="TravellingLark Logo" className="logo_footer hw_55px img-white" />
                            <div className="m-0 Inter-SemiBold fs_18">TravellingLark</div>
                        </Link>
                        <p className='fs_13 mt-2 bg-warning1 text-white'>TravellingLark is your one-stop shop for all your travel needs. Book your travel services like packages, hotels, guide etc. and enjoy your travel. Plan your next adventure with confidence.</p>
                    </div>
                    <div className='sb_footer_links_div'>
                        <h4 className='NunitoSans-SemiBold fs_16 text-white'>Quick Links</h4>
                        <Link to='/post-travel' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Post Your Travel</Link>
                        <Link to='/travel-leads' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Travel Lead</Link>
                        <Link to='/packages' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Packages</Link>
                        {/* <Link to='/hotels' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Hotels</Link>
                        <Link to='/guiders' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Guider</Link>
                        <Link to='/vehicle-search' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Vehicle</Link> */}
                        <Link to='/blogs' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Blogs</Link>
                    </div>
                    <div className='sb_footer_links_div'>
                        <h4 className='NunitoSans-SemiBold fs_16 text-white'>Partner with us</h4>
                        <Link to={`${vendor_url}`} target='_blank' rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Travel Agent Zone</Link>
                        <Link to={user1?.tkn && typeIs ? (serv?.includes('packages') ? `${vendor_url}redirect/view-packages/${user1?.tkn}` : vendor_url) : !typeIs ? vendor_url : null} onClick={()=> user1.tkn ? null : openME()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>List Your Package</Link>
                        <Link to={user1?.tkn && typeIs ? (serv?.includes('hotels') ? `${vendor_url}redirect/hotels/${user1?.tkn}` : vendor_url) : !typeIs ? vendor_url : null} onClick={()=> user1.tkn ? null : openME()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>List Your Vehicle</Link>
                        <Link to={user1?.tkn && typeIs ? (serv?.includes('view-leads') ? `${vendor_url}redirect/view-leads/${user1?.tkn}` : vendor_url) : !typeIs ? vendor_url : null} onClick={()=> user1.tkn ? null : openME()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>List Your Hotel</Link>
                        <Link to={user1?.tkn && typeIs ? (serv?.includes('photographer') ? `${vendor_url}redirect/photographer/${user1?.tkn}` : vendor_url) : !typeIs ? vendor_url : null} onClick={()=> user1.tkn ? null : openME()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Photographer</Link>
                        <Link to={user1?.tkn && typeIs ? (serv?.includes('visa-consultancy') ? `${vendor_url}redirect/visa-consultancy/${user1?.tkn}` : vendor_url) : !typeIs ? vendor_url : null} onClick={()=> user1.tkn ? null : openME()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Visa Consultancy</Link>
                        <Link to={user1?.tkn && typeIs ? (serv?.includes('activities') ? `${vendor_url}redirect/activities/${user1?.tkn}` : vendor_url) : !typeIs ? vendor_url : null} onClick={()=> user1.tkn ? null : openME()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Activities</Link>
                    </div>
                    <div className='sb_footer_links_div'>
                        <h4 className='NunitoSans-SemiBold fs_16 text-white'>Company</h4>
                        <Link to='/about-us' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>About Us</Link>
                        <Link to='/privacy-policy' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Privacy Policy</Link>
                        <Link to='/terms-and-conditions' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Terms & Conditions</Link>
                        <Link to='/user/tickets' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Support Ticket</Link>
                        <Link to='/contact-us' className='hover-bold-text mb-1 NunitoSans-Light fs_13'>Contact Us</Link>
                    </div>
                </div>

                <div className='sb_footer_below section_padding bg_thmlightdark' style={{ background: '#ffebe1' }}>
                    <div className='d-flex align-items-center justify-content-between w-100 flex-wrap'>
                        <p className='text_gray Inter-SemiBold fs_14 m-0 mb-xs1-20'>&copy; {new Date().getFullYear()} TravellingLark Ventures Private Limited All right reserved.</p>
                        <div className='d-flex gap-3  pe-5'>
                            <Link to={'https://www.facebook.com/profile.php?id=61571312276274&mibextid=ZbWKwL'} target='_blank' rel="noopener noreferrer" className='fs_13 m-0 border border-primary rd_50 hw_30px d_flex_align_justify_center pointer'><i className="fa-brands m-0 text-primary fa-facebook-f"></i></Link>
                            <Link to={'https://youtube.com/@travellinglark'} target='_blank' rel="noopener noreferrer" className='fs_13 m-0 border border-danger rd_50 hw_30px d_flex_align_justify_center pointer'><i className="fa-brands m-0 text-danger fa-youtube"></i></Link>
                            <Link to={'https://x.com/travellinglark'} target='_blank' rel="noopener noreferrer" className='fs_13 m-0 border border-dark rd_50 hw_30px d_flex_align_justify_center pointer'><i className="fa-brands m-0 text-dark fa-twitter"></i></Link>
                            <Link to={'https://www.linkedin.com/company/travellinglark'} target='_blank' rel="noopener noreferrer" className='fs_13 m-0 border border-primary rd_50 hw_30px d_flex_align_justify_center pointer'><i className="fa-brands m-0 text-primary fa-linkedin-in"></i></Link>
                            <Link to={'https://telegram.me/travellinglark'} target='_blank' rel="noopener noreferrer" className='fs_13 m-0 border border-primary rd_50 hw_30px d_flex_align_justify_center pointer'><i className="fa-brands m-0 text-primary fa-telegram"></i></Link>
                            <Link to={'https://www.instagram.com/travellinglark'} target='_blank' rel="noopener noreferrer" className='fs_13 m-0 border border-danger rd_50 hw_30px d_flex_align_justify_center pointer'><i className="fa-brands m-0 text-danger fa-instagram"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </div>
    )
}

export default Footer