import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { HTMLRenderNormal } from '../../Componets/HTMLRender'
import { OpenImageModal } from '../../Componets/Modal';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import DatePicker from 'react-datepicker';
import { GoogleMap } from '@react-google-maps/api';
import Counter from '../Hotels/Counter';
import { dateFormat } from '../../Componets/CommonFs';
import { tstSuccess } from '../../Componets/HotToast';
import SignInModal from '../Auth/SignInModal';
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

const key = process.env.REACT_APP_KEY;

function VehicleDetails() {
    const params = useParams();
    const navigate = useNavigate();

    const [open2, setOpen2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [err, setErr] = useState({})
    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [num, setNum] = useState('');
    const [description, setDescription] = useState('');
    const [location1, setLocation] = useState('');
    const [switchTab, setSwitchTab] = useState('Inclusions');
    const [data, setData] = useState([]);
    const [dates, setDates] = useState('')
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [adultChild, setAdultChild] = useState({
        adults: 1,
        children: 0
    });
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() + 1);

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = () => {
        setIsLoading1(true)
        CommonAPI({}, 'GET', `vehicleDetails?id=${params?.id}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);
            setData(dec1)
        } else {
            navigate('/not-found', { replace: true });
        }
        setIsLoading1(false)
    })

    const handleDateChange = (dt) => {
            const date = dateFormat(dt, 'dmy')
            setSelectedDate(date.replace(/\//g, '-'))
            setDates(dt)
    };


    const val = () => {
        const err = {};

        if (!value.trim()) {
            err.name = 'Enter valid name!'
        }
        if (!/^[^\s@]+@gmail\.com$/.test(email)) {
            err.mail = 'Invalid email format!'
        }

        if (num.trim().length < 10 || num.trim().length > 12) {
            err.num = 'Enter valid number!'
        }

        if (selectedDate == '') {
            err.date = 'Invalid date!'
        }

        setErr(err)
        return Object.keys(err).length == 0;
    }

    const handleSubmit = async (e, vId) => {
        e.preventDefault();

        const request = {
            name: value,
            email: email,
            phone: num,
            message: description,
            type: 'Vehicle',
            type_id: data[0]?._id,
            travelerCount: Number(adultChild.adults) + Number(adultChild.children),
            adult: adultChild.adults,
            child: adultChild.children || '0',
            travelDate: selectedDate,
            vendorId: data[0]?.vendor?._id
        }
        const enc1 = enc(request, key)

        if (val()) {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'requestCall', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            setValue('')
            setEmail('')
            setNum('')
            setDescription('')
            setAdultChild({ adults: 1, children: 0 })
            setSelectedDate('')
            setDates('')

            tstSuccess('Vehicle booking request send successfully')
        } else {

            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })


    return (
        <div
            className='mt-7 pb-5 bg-gray'
            style={{
                backgroundSize: 'contain',
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat',
                paddingTop: '50px',
            }}
        >
            <Container className='bg-success1 mt-5p'>
                <Row>
                    <Col lg={8} className=''>
                        {data && !isLoading1 && data?.map((item, i) => {
                            return (
                                <div key={i}>
                                    <div className='bg-white shadow-slowclean z-1 rd_6 p-4  ' style={{ zIndex: 2, position: 'relative' }}>
                                        <div className='d-flex flex-row align-items-start flex-column-xs mb-15px overflow-hidden'>
                                            <div className='bg-white shadow-n1 d-flex align-items-center p-3 rd_6 h_100px mt-5px mb-xs-20'>
                                                <img src='/images/wagonr.png' alt={item.name} className='img-fluid w_80px' />
                                            </div>
                                            <div className='ms-3 ml-0px-xs overflow-hidden'>
                                                <div className='Rubik-SemiBold fs_22'>{item.name}</div>
                                                <div className='Rubik-Regular fs_13 text-capitalize mb-10px'>Driver : {item.vendor?.business_name}</div>
                                                <div className='d-flex Rubik-Regular text-secondary fs_14 mb-2'>
                                                    <div className='d-align-justify-center'>
                                                        <span className="material-symbols-outlined fs-16px me-1">air_purifier_gen</span>
                                                        <div className='text-capitalize fs-13px'>{item.ac == 'Yes' ? 'AC' : 'Non-AC'}</div>
                                                    </div>

                                                    <div className='ms-2 d-align-justify-center'>
                                                        <span className="material-symbols-outlined fs-16px me-1 ">airline_seat_recline_extra</span>
                                                        <span className='fs-13px'>{item.sheets} Seats</span>
                                                    </div>

                                                    <div className='ms-2 Rubik-Regular fs_13 text-capitalize'><i className='bi bi-fuel-pump-fill me-2 fs-12px'></i>{item.fuelType}</div>
                                                    <div className='ms-2 Rubik-Regular fs_13 text-capitalize'><i className="fa-solid fa-bag-shopping me-2 fs-12px"></i>{item.luggage}</div>
                                                    <div className='ms-2 Rubik-Regular fs_13 text-capitalize'><i className="fa-solid fa-smoking me-2 fs-12px"></i>{item.smoking}</div>
                                                </div>

                                                <div className='d-flex Rubik-Regular text-secondary fs_14 mb-2  scroll-h w-100 overflow-hidden'>
                                                    <div className='Rubik-Regular fs_13 text-capitalize'>Waiting Charge : ₹{item.waitingCharges}</div>
                                                    <div className='ms-2 Rubik-Regular fs_13 text-capitalize'>Per km : ₹{item.chargesKm}</div>
                                                    <div className='ms-2 Rubik-Regular fs_13 text-capitalize'>One Day : ₹{item.chargesDay}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='Rubik-Regular fs_13 mb-1 '><i className='bi bi-check2-circle me-2'></i>65 kms included. After that ₹{item.charges}.0/km</div>
                                        <div className='Rubik-Regular fs_13 mb-1'><i className='bi bi-check2-circle me-2'></i>Free cancellation until 1 hour before pickup</div>
                                        <div className='Rubik-Regular fs_13 mb-1'><i className="fa-solid fa-car me-2"></i>{item.cities}</div>
                                    </div>
                                    <div className='bg-white rd_6 p-4' style={{ top: -15, position: 'relative' }}>

                                        <div className='Rubik-SemiBold fs_18 mt-3'>Facilities</div>
                                        <div className='px-1 mt-2 pb-0 rd_6 d-flex scroll-h'>
                                            {item.amenities.map((name, i) => (
                                                <div key={i} className='d-flex bg-white me-2 align-items-center text_gray px-2 p-1-5 rd_4 Source_Regular bg_lightblue'>
                                                    <p className='m-0 fs_12'>{name}</p>
                                                </div>
                                            ))}
                                        </div>


                                        {/* <div className='Rubik-SemiBold fs_18 mt-3'>Available Services In</div>
                                        <div className='px-1 mt-2 pb-0 rd_6 d-flex scroll-h'>
                                                <div key={i} className='d-flex bg-white me-2 align-items-center text_gray px-2 p-1-5 rd_4 Source_Regular bg_lightblue'>
                                                    <p className='m-0 fs_12'>{name}</p>
                                                </div>
                                            ))}
                                        </div> */}


                                        <div className='Rubik-SemiBold fs_18 mt-3'>Details</div>
                                        <p className='m-0 Source_Regular text_gray fs_13 mt-2'>
                                            <HTMLRenderNormal htmlContent={item && item?.details || ''} />
                                        </p>

                                        <div className='Rubik-SemiBold fs_18'>Gallery</div>
                                        <div className='bg-warning1 d-flex flex-wrap gap-3 mt-2'>
                                            {item && item?.gallery.map((img, i) => (
                                                <img src={`${img_base_url + img}`} alt='Vehicle Gallery Images' className='h-130px w-130px rd_6 pointer'
                                                    onClick={() => {
                                                        setSelectedImage(img)
                                                        setOpen2(true)
                                                    }} />
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </Col>

                    <Col>
                        <div className='bg-white rd_6 p-3' style={{}}>
                            <div className='Rubik-SemiBold fs_18'>Submit Booking Request</div>
                            <div className="form-group flex-grow-1 mt-3">
                                {/* <label className='Source_Medium fs_13 mb-1'>Full Name</label> */}
                                <input type="text" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Enter Full Name" value={value} onChange={(event) => setValue(event.target.value)} />
                                {err.name && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.name}</p>}
                            </div>

                            <div className="form-group flex-grow-1 mt-3">
                                {/* <label className='Source_Medium fs_13 mb-1'>Email</label> */}
                                <input type="email" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Enter Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                {err.mail && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.mail}</p>}
                            </div>

                            <div className="form-group flex-grow-1 mt-3">
                                {/* <label className='Source_Medium fs_13 mb-1'>Phone Number</label> */}
                                <input type="number" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.num ? 'mb-1' : 'mb-3'}`} placeholder="Phone number" value={num} onChange={(event) => setNum(event.target.value)} />
                                {err.num && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.num}</p>}
                            </div>


                            <div className='bg-warning1'>
                                {/* <label className='Source_Medium text_gray fs_12 mb-1 d-block pt-0-5'>Date of Travel</label> */}
                                <DatePicker
                                    selected={dates}
                                    className='form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                    onChange={handleDateChange}
                                    minDate={minDate}
                                    dateFormat='dd/MM/yy'
                                    placeholderText='Travel Date'
                                />
                                {err.date && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.date}</p>}
                            </div>

                            <div className="form-group flex-grow-1 mt-3">
                                <div className='form-control fs_13 h_38px rd_10 border_gray d-flex align-items-center pointer text_dark_medium' onClick={() => setOpen1(true)}>{adultChild.adults} Adults • {adultChild.children} Childrens</div>
                                {err.adult && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.adult}</p>}
                            </div>

                            <div className="form-group mb-3">
                                {/* <label className='Source_Medium fs_14 text-muted mb-1'>Enter Message</label> */}
                                <textarea name="description" placeholder='meesage' rows={4} className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" value={description} onChange={(event) => setDescription(event.target.value)} />
                                {err.msg && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.msg}</p>}
                            </div>

                            <button className='bg_thm btn w-100 mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Rubik-Medium p-1-5 px-2' disabled={isLoading} onClick={handleSubmit}>
                                {isLoading ? <Spinner size='sm' /> : 'Submit Request'}
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal
                title="Select adult and child"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open1}
                onOk={() => { setOpen1(false) }}
                onCancel={() => setOpen1(false)}
            >
                <Counter details={adultChild} setDetails={setAdultChild} type={'package'} />
            </Modal>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

            <OpenImageModal type='vehicle' isVisible={open2} onClose={setOpen2} img={selectedImage} />
        </div>

    )
}

export default VehicleDetails