import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import FilterQuery from './FilterQuery';
import { Modal, Spin } from 'antd';
import { dateFormat } from '../../../Componets/CommonFs';
import { CommonAPI } from '../../../Componets/CommonAPI';
import { dec } from '../../../Componets/SecRes';
import { useSelector } from 'react-redux';
import SignInModal from '../../Auth/SignInModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import LogOut from '../../../Componets/LogOut';
import { Helmet } from 'react-helmet';

const key = process.env.REACT_APP_KEY;

function Queries() {
    const [expanded, setExpanded] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [loadMore, setLoadMore] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [country, setCountry] = useState([])
    const [preference, setPreference] = useState([])
    const [selectedValues, setSelectedValues] = useState([100, 100000]);
    const [selectedValues1, setSelectedValues1] = useState([]);
    const [reload, setReload] = useState(false);
    const [stopReload, setStopReload] = useState(true);
    const userId = useSelector(reducer => reducer.data?.info?.user);
    const usertok = useSelector(reducer => reducer.data?.info);
    const [hasMore, setHasMore] = useState(true);



    const maxWord = 120

    useEffect(() => {
        if (reload && stopReload) {
            setList([])
            setStopReload(false)
        }
        leadsList();

    }, [page, selectedValues, selectedValues1, search, reload]);


    const leadsList = (filterId) => {
        const pref = preference ? preference : []
        // set

        // for auth
        const removeId = pref?.includes(filterId);
        const countryId = pref?.filter((id) => filterId !== id);
        const countryId1 = filterId && !removeId ? [...countryId, filterId] : [...countryId];

        // without auth
        const removeId2 = country?.includes(filterId);
        const countryId2 = country?.filter((id) => filterId !== id);
        const arrayCountryId2 = filterId && !removeId2 ? [...countryId2, filterId] : [...countryId2];

        const countryArray = userId?._id ? countryId1 : arrayCountryId2


        setIsLoading(true)
        // setList([])
        CommonAPI({}, 'GET', `queryList?query=${search}&remove=${removeId ? filterId : ''}&countryId=${filterId == 'TL' ? [] : countryArray?.length > 0 ? JSON.stringify(countryArray) : []}&price=${selectedValues[1] == '' || selectedValues?.length == 0 ? JSON.stringify([100, 220000]) : JSON.stringify(selectedValues)}&looking_for=${selectedValues1?.length > 0 ? JSON.stringify(selectedValues1) : selectedValues1}&page=${page}`, (res) => {
            if (res.status === true) {
                const dt = res.data && res?.data;
                const dec1 = dec(dt?.data, key)
                setPreference(dec1?.prefCountry)

                setReload(true)

                setList((prevItems) => [
                    ...prevItems,
                    ...dec1?.result.filter((newItem) => !prevItems.some((item) => item._id === newItem._id))
                ]);

                if (dec1?.result.length === 0) {
                    setHasMore(false);
                }

            } else {
                if (res.message == 'Invalid token') {
                    setIsModalOpen(true)
                    LogOut()
                }
            }
            setIsLoading(false)
        })
    }



    const handleCheckboxChange = (value, type) => {
        setList([])
        setPage(1)

        if (type == 'rng') {
            setSelectedValues(value)

        } else if (type == 'pkg') {
            setSelectedValues1(prevValues => {
                if (prevValues.includes(value)) {
                    return prevValues?.filter(item => item !== value);
                } else {
                    return [...prevValues, value];
                }
            });
        } else {
            leadsList(value);
            if (value == 'TL') {
                setCountry([])
                return
            }


            if (userId?._id) {
                setCountry([value == undefined ? [] : value])


                if (type == 'remove') return
                if (!value) return
                setPreference(prevValues => [...prevValues, value]);

            } else {
                setCountry(prevValues => {
                    if (value && !prevValues.includes(value)) {
                        return [...prevValues, value];
                    } else if (prevValues.includes(value)) {
                        return prevValues.filter(item => item !== value);
                    }
                    return prevValues;
                });
                // setCountry([value == undefined ? [] : value])
            }
        }
    };


    const handleClick = (event, i) => {
        event.preventDefault();
        setExpanded(prev => ({
            ...prev,
            [i]: !prev[i]
        }));
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            setOpen1(false);
        }, 500);
    };


    const fetchMoreData = () => {
        setPage(page + 1);
    };


    return (
        <div className='min-vh-100 pt-2 d-flex flex-column align-items-center bg-gray' style={{ backgroundColor: '#fff' }}>

            <div className='w_92 bg-succes1s mb-5 d-flex align-items-center flex-column'>

                <div className='mb-2 mt-4 d-flex align-items-center w-100 d-sm-none'>
                    <p className='Source_Medium text_thm p-1 px-2 rd_6 fs_14 bg_thmlight pointer m-0 ' onClick={() => setOpen1(true)}>FILTER <i className='bi bi-filter '></i></p>
                </div>

                <Helmet>
                    <meta charSet="UTF-8" />
                    <title>Travel Leads Provider Company | Best Travel Leads Provider for Agencies | Travellinglark</title>
                    <meta name="description" content='Boost your travel business with quality travel leads online. Partner with TravellingLark, the trusted travel leads provider, to access targeted leads for packages.' />
                    <meta name="keywords" content="Quality travel leads online, Travel agency leads for packages, travel leads provider company, travel leads provider, best travel leads provider" />
                    <link rel="canonical" href="https://www.travellinglark.com/travel-leads/" />
                </Helmet>

                <Row className='rg_30px bg-warning1 w-100 mt-4'>
                    <Col xs={0} sm={0} md={4} lg={3} className='d-none-on-xs'>
                        <FilterQuery selectedCountries={country || []} preference={preference || []} onChange={handleCheckboxChange} />
                        <h2 className='fs-16px Inter-SemiBold mt-40px'>Best Travel Leads Provider India</h2>
                        <p className='fs-13px Inter-Regular mt-5px'>
                            TravellingLark is a trusted name when it comes to connecting travel agencies with high-quality, verified leads. As the best travel leads provider, we specialize in delivering quality travel leads online that match your services, helping you grow your business effortlessly.
                            <br /><br />
                            Our platform bridges the gap between travelers and travel professionals, making us the go-to travel leads provider company for agencies seeking genuine inquiries. Whether you specialize in holiday packages, luxury tours, or customized trips, TravellingLark ensures you get targeted travel agency leads for packages that align with your expertise.
                            <br /><br />
                            As the leading travel leads provider, TravellingLark ensures you don’t waste time on low-quality inquiries. Instead, focus on converting high-intent leads into loyal customers.
                            <br /><br />
                            Join TravellingLark today and experience why we’re recognized as the best travel leads provider in the industry. Let us help you unlock new growth opportunities and reach travelers worldwide.
                        </p>
                    </Col>

                    <Col xs={12} sm={12} md={8} lg={9} className='bg-primary1'>
                        <h1 className='fs-16px Inter-SemiBold'>Package Leads For Travel Agency</h1>
                        <div className='bg-white shadow-slowclean d-flex align-items-center flex-column flex-sm-row rd_6 p-3'>
                            <div className='bg-primary11 fs_13 h_38px d_flex_align_justify_center rd_10 border_gray Rubik-Regular text_dark_medium p-0 me-sm-2 flex-grow-1 w-md-100'>
                                <i className='fa fa-search text_gray ms-2 me-1'></i>
                                <input
                                    className='form-control outline-none border-0 text_gray fs_13 rd_10 px-2'
                                    type='text'
                                    placeholder='Search'
                                    style={{ height: 35, color: 'red' }}
                                    value={search}
                                    onChange={(event) => { setSearch(event.target.value); setList([]); setPage(1) }}
                                />
                            </div>
                        </div>

                        {isLoading && (
                            <div className='d-flex justify-content-center mt-4'>
                                <Spin className='thm-spin' />
                            </div>
                        )}

                        <Row className='bg-warning1 gap-3 mt-4'>

                            {list?.length > 0 && (
                                <InfiniteScroll
                                    className='hide-scroll'
                                    dataLength={list?.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<div className='w-100 d-flex justify-content-center mt-3'>
                                        <Spin className='thm-spin' />
                                    </div>}
                                    endMessage={<p className='Rubik-Regular fs_11 m-0 p-0 text-secondary text-center mt-3'>No More data found</p>}
                                >
                                    {list && list?.map((itm, i) => {
                                        const user = itm.userDetails && itm?.userDetails
                                        const dt = dateFormat(itm.createdAt, 'date')
                                        const word = itm && itm.description && itm.description.split(/\s+/);

                                        const peoples = itm?.adult + itm?.child

                                        const isExpanded = expanded[i] || false
                                        if (itm?.deletedAt !== 1) {
                                            return (
                                                <Col lg={12} key={i}>
                                                    <div className={`rd_6 pointer move_btt bg-white mb-3 shadow-slowclean p-3`}>
                                                        <Link to={`/travel-lead/details/${itm.id}`}
                                                            className='text-decoration-none position-relative'>
                                                            <div className='d-flex mb-5px'>
                                                                <p className='fw-bold text_gray flex-grow-1 m-0 text-capitalize'>{user?.first_name + ' ' + user?.last_name}</p>
                                                                <p className='Inter-Medium m-0 text_gray fs_12'>{dt}</p>
                                                            </div>

                                                            <p className=" Source_Regular fs_14 text-muted z-3 mt-10px">
                                                                <span className="show-on-large">
                                                                    {isExpanded ? word.join(' ') : word.slice(0, maxWord).join(' ')}
                                                                    {!isExpanded && word.length > maxWord && '...'}
                                                                </span>
                                                                <span className="show-on-small">
                                                                    {isExpanded ? word.join(' ') : word.slice(0, 70).join(' ')}
                                                                    {!isExpanded && word.length > 70 && '...'}
                                                                </span>
                                                                {word.length > 70 && (
                                                                    <span className='text-primary pointer fs_12 fw-bold z-5' onClick={(event) => handleClick(event, i)}>{isExpanded[i] ? ' See Less' : ' See More'}</span>
                                                                )}
                                                            </p>

                                                            <div className='d-flex align-items-center flex-wrap bg-warning1 mt-4 gap-2'>
                                                                <p className='Inter-Medium m-0 text-muted fs_12'>• Budget: {itm.currency?.code}{itm.budget}</p>
                                                                <p className='Inter-Medium m-0 text-muted fs_12'>• {itm.totalProposals} Offers</p>
                                                                <p className='Inter-Medium m-0 text-muted fs_12'>• {peoples} Travelers</p>
                                                                <p className='Inter-Medium m-0 text-muted fs_12'>• {itm.duration} Duration</p>
                                                                <p className='Inter-Medium m-0 text-muted fs_12'>Destination Country: {itm.queryCountry && itm?.queryCountry[0]?.name}</p>
                                                                {itm.destinationCountry && <p className='Inter-Medium m-0 text-muted fs_12 flex-grow-1'>• Departure Country: {itm.destinationCountry && itm?.destinationCountry?.name}</p>}
                                                            </div>

                                                            <div className='d-flex align-items-start bg-warning1 mt-3'>
                                                                {itm?.looking_for && itm?.looking_for.map((category, index) => (
                                                                    <p className='mr_5 Source_Regular fs-11px rounded-pill p-2 px-3 text_thm border_thm m-0 fw-normal badge' key={index}>{category}</p>
                                                                ))}
                                                                <p className='flex-grow-1' />

                                                                <div className='btn border_thmdark text_thmdark fs_12 d-none-519'>
                                                                    Send Offer
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    })}
                                </InfiniteScroll>
                            )}

                            {/* {!isLoading && list && list?.length == 0 && (
                                <div className='d_flex_align_justify_center flex-column mt-5'>
                                    <img src='/icons/not-found.png' alt='not foud' className='img-fluid h_100px w_100px mt-5' />
                                    <h5 className='Source_SemiBold mt-4'>no data found</h5>
                                </div>
                            )} */}
                        </Row>
                    </Col>
                </Row>
            </div>

            <Modal
                title="FILTER"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 15px'
                    },
                    header: {
                        padding: '10px 10px 0 10px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open1}
                onOk={handleOk}
                onCancel={() => setOpen1(false)}
            >
                <FilterQuery preference={preference || []} onChange={handleCheckboxChange} />
            </Modal>

            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

        </div >
    )
}

export default Queries