import { Route, Routes} from "react-router-dom";
import PkgIndex from "../Pages/Packages/PkgIndex";
import Nav from "./Nav";
import ScrollToTop from "../Componets/ScrollToTop";
import QueryDetails from "../Pages/Dashboard/Query/QueryDetails";
import AddQuery from "../Pages/Dashboard/Query/AddQuery";
import Queries from "../Pages/Dashboard/Query/Queries";
import Footer from "../Componets/Footer";
import Profile from "../Pages/Menu/Profile";
import Settings from "../Pages/Menu/Settings";
import MyQueries from "../Pages/Menu/MyQueries";
import PkgDetails from "../Pages/Packages/PkgDetails";
import HotelIndex from "../Pages/Hotels/HotelIndex";
import HotelDetails from "../Pages/Hotels/HotelDetails";
import Guider from "../Pages/Guider/Guider";
import MyBookings from "../Pages/Menu/MyBookings";
import VehicleList from "../Pages/Vehicle/VehicleList";
import GuiderDetails from "../Pages/Guider/GuiderDetails";
import PrivacyPolicy from "../Pages/FooterLinks/PrivacyPolicy";
import TC from "../Pages/FooterLinks/TC";
import RefundCancel from "../Pages/FooterLinks/RefundCancel";
import Blogs from "../Pages/FooterLinks/Blogs/Blogs";
import BlogDetails from "../Pages/FooterLinks/Blogs/BlogDetails";
import About from "../Pages/FooterLinks/About";
import STicket from "../Pages/Ticket/STicket";
import TicketDetails from "../Pages/Ticket/TicketDetails";
import PkgList from "../Pages/Packages/PkgList";
import VehicleDetails from "../Pages/Vehicle/VehicleDetails";
import React from "react";
import ContactForm from "../Pages/FooterLinks/ContactForm";
import NotFound from "../Componets/NotFound";
import VendorDetails from "../Pages/Dashboard/VendorDetails";
import ProposalDetails from "../Pages/Dashboard/Query/ProposalDetails";
import Home from "../Pages/Dashboard/Home";
import ComingSoon from "../Componets/ComingSoon";
import MessagingPopup from "../Pages/Dashboard/MessagingPopup";
import { ModalWrapper, MsgNoti } from "../Componets/Modal";
import PkgByCategory from "../Pages/Packages/PkgByCategory";
import BottomNavigation from "./BottomNavigation";
import MenuPage from "../Pages/Menu/MenuPage";
import TripsAbout from "../Pages/Packages/TripsAbout";

const RouterIndex = () => {
    return (
      <>
        <ScrollToTop />
        <MsgNoti />
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<><Nav /><BottomNavigation /> <Home /><Footer /><MessagingPopup type="common" /></>} />
          
          {/* Vendor Routes */}
          <Route path="/profile/:name/:id" element={<><Nav /><BottomNavigation /> <VendorDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Package Routes */}
          <Route path="/packages" element={<><Nav /><BottomNavigation /> <PkgIndex /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/packages/list" element={<><Nav /><BottomNavigation /> <PkgList /><Footer /><MessagingPopup type="common" /></>} />
          {/* <Route path="/packages/:category" element={<><Nav /><BottomNavigation /> <PkgListByCategory /><Footer /><MessagingPopup type="common" /></>} /> */}
          <Route path="/packages/:category" element={<><Nav /><BottomNavigation /> <PkgList /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/package/:slug/:id" element={<><Nav /><BottomNavigation /> <PkgDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Trips Routes */}
          <Route path="/:parent/:slug/:id" element={<><Nav /><BottomNavigation /> <TripsAbout /><Footer /><MessagingPopup type="common" /></>} />
          {/* <Route path="/country/:slug/:id" element={<><Nav /><BottomNavigation /> <TripsAboutCountry /><Footer /><MessagingPopup type="common" /></>} /> */}
          <Route path="/:parent/:type_pkg/:slug/:id" element={<><Nav /><BottomNavigation /> <PkgByCategory /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Hotel Routes */}
          <Route path="/hotels" element={<><Nav /><BottomNavigation /> <HotelIndex /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/hotel/:slug/:id" element={<><Nav /><BottomNavigation /> <HotelDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Query Routes */}
          <Route path="/post-travel" element={<><Nav /><BottomNavigation /> <AddQuery /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/travel-lead/details/:id" element={<><Nav /><BottomNavigation /> <QueryDetails /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/travel-leads" element={<><Nav /><BottomNavigation /> <Queries /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/offer/details" element={<><Nav /><BottomNavigation /> <ProposalDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Guider Routes */}
          <Route path="/guiders" element={<><Nav /><BottomNavigation /> <Guider /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/guiders/details" element={<><Nav /><BottomNavigation /> <GuiderDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Vehicle Routes */}
          {/* <Route path="/vehicle-search" element={<><Nav /><BottomNavigation /> <Vehicleindex /><Footer /><MessagingPopup type="common" /></>} /> */}
          <Route path="/vehicles" element={<><Nav /><BottomNavigation /> <VehicleList /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/vehicle/details/:id" element={<><Nav /><BottomNavigation /> <VehicleDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Profile Routes */}
          <Route path="/profile" element={<><Nav /><BottomNavigation /> <Profile /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/settings" element={<><Nav /><BottomNavigation /> <Settings /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/my-travels" element={<><Nav /><BottomNavigation /> <MyQueries /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/my-proposals" element={<><Nav /><BottomNavigation /> <MyQueries /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/mybookings" element={<><Nav /><BottomNavigation /> <MyBookings /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Legal Routes */}
          <Route path="/privacy-policy" element={<><Nav /><BottomNavigation /> <PrivacyPolicy /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/terms-and-conditions" element={<><Nav /><BottomNavigation /> <TC /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/refund-and-cancellation" element={<><Nav /><BottomNavigation /> <RefundCancel /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Blog Routes */}
          <Route path="/blogs" element={<><Nav /><BottomNavigation /> <Blogs /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/blog/:slug/:id" element={<><Nav /><BottomNavigation /> <BlogDetails /><Footer /><MessagingPopup type="common" /></>} />
          
          {/* About Us Route */}
          <Route path="/about-us" element={<><Nav /><BottomNavigation /> <About /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* User Routes */}
          <Route path="/user/tickets" element={<><Nav /><BottomNavigation /> <STicket /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/user/ticket-details" element={<><Nav /><BottomNavigation /> <TicketDetails /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Contact Us Route */}
          <Route path="/contact-us" element={<><Nav /><BottomNavigation /> <ContactForm /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* 404 Not Found Route */}
          <Route path="/not-found" element={<><Nav /><BottomNavigation /> <NotFound /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="*" element={<><Nav /><BottomNavigation /> <NotFound /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Coming Soon Route */}
          <Route path="/coming-soon" element={<><Nav /><BottomNavigation /> <ComingSoon /><Footer /><MessagingPopup type="common" /></>} />
          <Route path="/menu" element={<><Nav /><BottomNavigation /> <MenuPage /><Footer /><MessagingPopup type="common" /></>} />
  
          {/* Sign In Route */}
          <Route path="/signin" element={<ModalWrapper />} />

        </Routes>

      </>
    );
  };
  
  export default RouterIndex;

