import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import OffcanvasNav from '../Componets/Offcanvas';
import MenuPage from '../Pages/Menu/MenuPage';

const BottomNavigation = () => {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

    const home = window.location.pathname === '/'
    const post = window.location.pathname.startsWith('/post');
    const travel = window.location.pathname.startsWith('/travel');
    const menu = window.location.pathname.startsWith('/signin');

    return (
        <div className="bottom-nav">
            <NavLink to="/" className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-house ${home ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={home ? 'label show' : 'label text_gray2'}>Home</span>
            </NavLink>

            <NavLink to="/post-travel" className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-pencil-square ${post ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={post ? 'label show' : 'label text_gray2'}>Post Your Travel</span>
            </NavLink>

            <NavLink to="/travel-leads" className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-list-check ${travel ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={travel ? 'label show' : 'label text_gray2'}>Travel Leads</span>
            </NavLink>

            <div onClick={toggleOffcanvas} className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-menu-button-wide ${menu ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={menu ? 'label show' : 'label text_gray2'}>Menu</span>
            </div>

            {/* Offcanvas Slider open when click on menu toggle button */}
            <MenuPage show={showOffcanvas} handleClose={toggleOffcanvas} />
        </div>
    );
};

export default BottomNavigation;
