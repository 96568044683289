import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation, useParams } from 'react-router-dom';
import Map from '../Hotels/Map';
import RatingStars from '../../Componets/RatingStars';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { avgRatingCalculate, dateFormat, reviewOfSummary } from '../../Componets/CommonFs';
import MessagingPopup from './MessagingPopup';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { svReceiverDetails } from '../../ContextRedux/Action';
import SignInModal from '../Auth/SignInModal';
import { FeedbackModal, ShareModal } from '../../Componets/Modal';
import { useChatPopup } from '../../ContextRedux/ChatPopupContext';
import { tstError } from '../../Componets/HotToast';
import { HTMLRender } from '../../Componets/HTMLRender';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const socket_url = process.env.REACT_APP_SOCKET_URL;
// const socket_url = process.env.REACT_APP_SOCKET_LOCAL;
// const socket = io(socket_url);

function VendorDetails() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { name, id } = useParams();


  const state = location?.state;

  const [role, setRole] = useState('about');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { isChatOpen, toggleChatPopup } = useChatPopup();
  const [renderUseEffect, setRenderUseEffect] = useState(false);

  // pkg
  const [loadMore, setLoadMore] = useState(true);

  // review
  const [list2, setList2] = useState([])
  const [loadMore2, setLoadMore2] = useState(true);
  const [switchedTabs, setSwitchedTab] = useState([]);
  const socketRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [vendor, setVendor] = useState([]);
  const [packages, setPackages] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const user = useSelector(reducer => reducer.data?.info?.user)
  const user_token = useSelector(reducer => reducer.data?.info?.tkn)
  const [open3, setOpen3] = useState(false);
  const [hideBtn, setHideBtn] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const url = window.location.href;


  useEffect(() => {
    if (user_token) {
      socketRef.current = io(socket_url, {
        query: {
          token: user_token
        }
      });
    }
  }, [user_token, renderUseEffect])


  useEffect(() => {
    if (!switchedTabs.includes(role)) {
      getDeatils();
    }

  }, [role])

  const getDeatils = async () => {
    const data = {
      digitId: Number(id),
      slug: name,
      // id: state?.id,
      page: page,
      type: role == 'about' ? '' : role
    }
    const enc1 = enc(data, key);

    setIsLoading(true)
    CommonAPI(enc1, 'POST', `vendorProfile`, responseBack2)
    CommonAPI({}, 'GET', `reviewList?typeId=${state?.id}`, responseBack3)
  }

  const responseBack2 = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt, key);


      setSwitchedTab((prev) => [...prev, role]);
      if (role == 'package') {
        setPackages(dec1?.typedata)
      } else if (role === 'hotel') {
        setHotels(dec1?.typedata)
      } else if (role === 'review') {
        setList2(dec1?.typedata?.reviews)
        if (dec1?.typedata?.reviews == undefined || dec1?.typedata?.reviews == null) {
          setList2(dec1?.typedata?.reviews)
        } else {
          setList2(prevData => {
            const newData = dec1?.typedata?.reviews?.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
            return [...prevData, ...newData];
          });
        }

        if (dec1?.packages?.length < 10) {
          setLoadMore(false);
        }
      } else {
        setVendor(dec1?.vendor)
      }
    } else {

    }
    setIsLoading(false)
  })

  const responseBack3 = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt, key);
      setList2(dec1?.data)

      // if (dec1?.packages == undefined || dec1?.packages == null) {
      //   setList2(dec1?.packages)
      // } else {
      //   setList2(prevData => {
      //     const newData = dec1?.packages?.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
      //     return [...prevData, ...newData];
      //   });
      // }

      // if (dec1?.packages?.length < 10) {
      //   setLoadMore(false);
      // }
    }
    setIsLoading(false)
  })

  const since = dateFormat(vendor[0]?.createdAt, 'dmy')


  const changeRole = (role) => {
    setRole(role)
  }

  const handleStartChat = async (name, id) => {
    if (user?._id) {
      const createObject = { sender_id: user?._id, receiver_id: id }
      const create = enc(createObject, key)
      try {
        const joinO = { user_id: user?._id }
        const join = enc(joinO, key)
        socketRef.current.emit('join', join, (join)=>{
        })

        socketRef.current.emit('create_chat', create, (res) => {
          dispatch(svReceiverDetails({ receiver_id: id, name: name, chat_id: res.chat_id }))
          toggleChatPopup()
        })
      } catch (error) {

        tstError('Please try again!')
        setRenderUseEffect(true)
      }
    } else {
      setIsModalOpen(true)
    }
  }

  const { summary, avgRating } = reviewOfSummary(list2);

  const services = [
    {name:"About Company", id:'about'}, 
    {name:"Packgaes", id:'packages'}, 
    {name:"Hotels", id:'hotels'}, 
    {name:"Vehicles", id:'vehicle'}, 
    {name:"Reviews", id:'review'}]

  return (
    <div className='d-flex flex-column align-items-center mt-6 pt-3 p-0 pb-5' style={{ backgroundColor: '#eef4fd' }}>

      <div className='w_95 mt-4 d-flex align-items-center flex-column w-100'>

        <Row className='bg-white rd_4 p-3 w-100'>
          <Col className='bg1-warning ps-1 mb-xs1-20'>
            <img src={vendor[0]?.image ? img_base_url + vendor[0]?.image : '/icons/blank_profile.png'} alt='Vendor Image' className='img-fluid h_150px w_200px' />
          </Col>

          <Col sm={8} md={9} lg={10} xl={10} className=''>
            <div className='bg1-secondary'>
              <div className='d-flex justify-content-between '>
                <div className=''>
                  <div className='Rubik-Medium '>{vendor[0]?.business_name}</div>
                  <div className='Rubik-Regular mb-1 text-secondary fs_13 mt-2'><strong>Member Since:</strong> {since}</div>
                </div>
                {(user?._id == state?.queryId) && (
                  <div className='show-on-large'>
                    <div className='mt-3 bg-gradient-thm h_35px pointer d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => handleStartChat(vendor[0]?.business_name, vendor[0]?._id)}>
                      <p className='Rubik-Regular fs_12 text-white m-0'><i className="fa-regular fa-comment-dots me-1 fs_13"></i> Chat with US</p>
                    </div>
                  </div>)}
              </div>
              {/* <div className='Rubik-Regular mb-1 text-secondary fs_13'><strong className=''>Reviews:</strong> {vendor[0]?.totalReview}</div> */}
              <div className='Rubik-Regular mb-1 text-secondary text-capitalize fs_13'><strong className=''>Services:</strong> {vendor[0]?.services?.join(', ')}</div>
              <div className='Rubik-Regular mb-1 text-secondary fs_13 '><strong className=''>Address:</strong> {vendor[0]?.address}</div>
              {vendor[0]?.destinations?.length > 0 && <div className='Rubik-Regular mb-1 text-secondary fs_13 '><strong className=''>Destinations:</strong> {vendor?.length > 0 && vendor[0]?.destinations.map((item) => item.name).join(', ')}</div>}
              {(user?._id == state?.queryId) && (
                <div className='show-on-small w-100'>
                  <div className='mt-3 bg-gradient-thm h_35px pointer d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => handleStartChat(vendor[0]?.business_name, vendor[0]?._id)}>
                    <p className='Rubik-Regular fs_12 text-white m-0'><i className="fa-regular fa-comment-dots me-1 fs_13"></i> Chat with US</p>
                  </div>
                </div>
              )}
              <div className=' pt-0 mt-3 me-3 bg_thm pointer h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => setIsModalOpen2(true)}>
                <p className='Rubik-Regular fs_12 text-white m-0 fa-fade'><i className="fa-solid fa-share-nodes me-1 fs_13"></i> Share</p>
              </div>
            </div>
          </Col>
        </Row>

        {/* tabs */}
        <div className='flex-wrap gap-3 bg1-warning w-100 Rubik-Regular fs_14 text-secondary d-flex align-items-center mb-3 mt-4-5'>
          {services
          ?.map((tab) => {
            return (
              <div key={tab.id}
                className={`nav-link-booking flex-shrink-0 pointer d-flex justify-content-center align-items-center px-2-5 p-1 rd_50 ${role === tab.id ? 'active' : ''}`}
                onClick={() => changeRole(tab.id)}
              >
                <i className="bi bi-box-seam fa-fade mr_10"></i>
                {tab.name}
              </div>
            )
          })}

          {/* <div
            className={`nav-link-booking flex-shrink-0 pointer d-flex justify-content-center align-items-center px-2-5 p-1 rd_50 ${role === 'hotel' ? 'active' : ''}`}
            onClick={() => changeRole('hotel')}
          >
            <i className="bi bi-building fa-fade mr_10"></i>
            Hotels
          </div>

          <div
            className={`nav-link-booking flex-shrink-0 pointer d-flex justify-content-center align-items-center px-2-5 p-1 rd_50 ${role === 'review' ? 'active' : ''}`}
            onClick={() => changeRole('review')}
          >
            <i className="bi bi-star-half fa-fade mr_10"></i>
            Reviews
          </div>

          <div
            className={`nav-link-booking flex-shrink-0 pointer d-flex flex-row justify-content-center align-items-center px-2-5 p-1 rd_50 ${role === 'about' ? 'active' : ''}`}
            onClick={() => { setRole('about') }}
          >
            <i className="bi bi-file-person fa-fade mr_10"></i>
            About Company
          </div> */}
        </div>

        {role == 'about' ? (
          <Row className='bg-white rd_4 p-2 w-100 mt-2'>
            <Col className='bg-warning1'>
              <div className='Rubik-SemiBold'>About Company</div>
              <div className='divider h_05px mt-2 mb-3'></div>
              <div className='Rubik-Regular fs_14 text-secondary'>
                <HTMLRender htmlContent={vendor[0]?.about || ''} />
              </div>

              <Map address={vendor[0]?.address} />
            </Col>
          </Row>

        ) : role == 'packages' ? (

          <Row className='bg-warning1 d-flex justify-sm-center w-100 mt-3'>
            {/* Listing */}
            {packages && packages.map((item, i) => {
              const lowPrice = item.discountPrice
              const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
              const vendor = item?.vendorId
              return (
                <Col xs={6} sm={12} key={i} className="xl-4 lg-3 md-3 sm-2 xs-1 m-xxs-auto mb-4 w-xxs-99 bg-success1 px-2 px-xs-0">
                  <div className="package m-1 mt-0 position-relative rd_10 bg-white mb-xs-20 overflow-hidden h_230px1 pkg-btn-hover">
                    <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} className='text-decoration-none hover-me'>
                      <img src={`${img_base_url + item.image}`} alt='Tour Package Image' className='img-fluid mty-image h_150px w-100 position-relative overflow-hidden' />
                      <div className='p-1 px-2-5' style={{
                        position: 'absolute',
                        top: 0,
                        width: 'auto',
                        height: 'auto',
                        borderBottomRightRadius: 10,
                        background: 'rgba(0,0,0,0.3)'
                      }}>
                        <p className='Source_Regular text-white fs_11 m-0 text-capitalize'>{item.duration}</p>
                      </div>


                      <div className="p-3 overflow-hidden position-relative">
                        <h3 className='Rubik-SemiBold text_gray fs_13 spacing text-capitalize mini-line-1 m-0'>{item.name}</h3>
                        <p className='Rubik-Regular fs_11 text_gray2 mini-line-1 m-0'>{item?.category?.name}</p>


                        <div className='d-flex'>
                          {/* <img src={`${vendor?.image == null ? '/icons/user.png' : img_base_url + vendor?.image}`} className='hw_15px rd_50 mr_5' /> */}
                          <p className='Source_Regular text_gray fs_12 m-0 text-capitalize'>By {vendor?.business_name}</p>
                        </div>

                        <div className='d-flex align-items-center justify-content-between mt-3'>
                          <div>
                            <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>₹{final}</strong>/Person</div>
                            {lowPrice != null && lowPrice > 0 && (
                              <div className='Rubik-Regular fs_12 TxtCut text-secondary'>₹{item.price}</div>
                            )}
                          </div>
                          <div className='book-now border_thmdark rounded-5 fs_12 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              )
            })}

            {(!isLoading && packages == undefined || packages?.length == 0) && (
              <div className='d_flex_align_justify_center flex-column mt-5 mb-2'>
                <img src='/images/not_data.webp' alt='not found' className='img-fluid h_130px w_200px mt-5' />
                <div className='Rubik-Regular text_gray2 mt-2'>No packages available</div>
              </div>
            )}
          </Row>
        ) : role == 'hotels' ? (
          <>
            <Row className='bg-primary1 mt-4 w-100'>
              {hotels && hotels?.map((item) => {
                const add = item.address || 'Not available'
                const nm = item.name
                const name = nm.length > 30 ? nm.slice(0, 30) + '...' : nm

                const lowPrice = item.discountPrice
                const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice

                const discount = final - item.price;
                const percentage = (discount / item.price) * 100

                return (
                  <Col xs={12} sm={12} xl={6} className=" m-xxs-auto mb-4 w-xxs-99 bg-success1 px-2 px-xs-0">
                    <div key={item._id} className=' w-100 mb-xs-10 bg-white rd_6 mb-4 p-0 overflow-hidden position-relative'>
                      <Link to={`/hotel/${item?.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className=' p-2 d-flex w-100 flex-column-sm text-decoration-none hover-me'>
                        <div className='w-md-100'>
                          <img src={`${img_base_url + item.image}`} alt='hotel image' className='img-fluid h_180px w_340px rd_6 w-md-100' />
                        </div>
                        <div className="ms-2 p-1 mt-sm-10 bg-warning1 w-100 d-flex flex-column">
                          <h3 className='Rubik-SemiBold text_gray fs_15 fs-xs-14 mb-1 text-capitalize'>{name}</h3>
                          <div className='d-flex align-items-start'>
                            <i className="fa-regular fa-building fa-fade text_gray2 mt-1 fs_14 me-1"></i>
                            <span className='Source_Regular text_gray2 fs_14 m-0 mini-line-1'>{add}</span>
                          </div>

                          {item.totalReviews !== 0 && (
                            <div className='d-flex mt-2'>
                              <div className='d-flex align-items-center p-0-5 px-2 rd_4 d-inline-flex' style={{ backgroundColor: '#e5fdea', color: '#13af35' }}>
                                <div className='Rubik-Medium fs_13'>{item.avgRating}</div>
                                <i className="bi bi-star-fill ms-1 fs_12"></i>
                              </div>
                              <p className='Source_Regular text_gray2 fs_14 m-0 ms-2'>({item.totalReviews} Ratings)</p>
                            </div>
                          )}

                          <div className='flex-grow-1'></div>

                          <div className='d-flex flex-wrap gap-2 mt-4 mb-1'>
                            {item?.amenities?.slice(0, 3).map((amenity) => (
                              <div className='d-flex bg-white align-items-center text_gray px-2 p-1-5 rd_4 Source_Regular bg_lightblue'>
                                <img className="rd_10 hw_15px me-2 img-gray" src={`${img_base_url + amenity.image}`} alt={amenity.name} />
                                <p className='m-0 fs_12'>{amenity.name}</p>
                              </div>
                            ))}
                          </div>

                          <div className='flex-grow-1'></div>


                          <div className='d-flex align-items-center w-100 mt-sm-10'>
                            <div className='flex-grow-1'>
                              <div className='d-flex align-items-center'>
                                <div className='Rubik-SemiBold fs_18 text_gray'>₹{final}</div>
                                {lowPrice != null && lowPrice > 0 && (
                                  <>
                                    <div className='Rubik-Regular fs_13 text_gray2 ms-2 text-decoration-line-through'>₹{item.price}</div>

                                    <div className='Rubik-SemiBold fs_14 text_thm ms-2'>{percentage?.toFixed(2)}%</div>
                                  </>
                                )}
                              </div>
                            </div>

                            <div className='btn border_thmdark rd_4 text_thmdark fs_12 d-none-519'>
                              Request Callback
                            </div>

                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                )
              })}

              {(!isLoading && hotels == undefined || hotels?.length == 0) && (
                <div className='d_flex_align_justify_center flex-column mt-5'>
                  <img src='/icons/not-found.png' alt='not found' className='img-fluid h_100px w_100px mt-5' />
                  <h5 className='Source_SemiBold mt-4'>no data found</h5>
                </div>
              )}
            </Row>
          </>
        ) : role == 'review' ? (
          <>
            {!isLoading && list2?.length > 0 && (
              <>
                <Row className='bg-white rd_4 p-3 w-100 mt-3 m-3'>
                  <Col sm={12} md={5} lg={6} xl={6} className='mb-md-4'>
                    <div className='Rubik-Medium text-center text-success mb-3 mt-2 fs_17'>Reviews ({list2 && list2?.length})</div>
                    <div className="text-center">
                      <div className="Inter-Medium text-muted">
                        <span className="rating-value">Average Rating {avgRating} </span>
                      </div>
                      <div className="">
                        {/* Five stars symbol */}
                        <RatingStars rating={avgRating} />
                      </div>

                      <div className='mt-3 bg-gradient-blue h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => (hideBtn || vendor?.[0].reviewStatus == 1) ? null : setOpen3(true)}>
                        <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-comment-dots me-1 fs_13"></i> {(hideBtn || vendor?.[0].reviewStatus == 1) ? 'Already Submitted Review' : 'Write a Review'}</p>
                      </div>
                    </div>
                  </Col>

                  <Col className=''>
                    <div className="progress-bars">
                      {summary.map((item) => (
                        <div key={item.rating} className="d-flex align-items-center justify-content-center mb-3">
                          <div className="Rubik-Regular d-flex align-items-center">
                            {item.rating}
                            <i className="fa-solid fa-star text_thm fs_13 ms-2"></i>
                          </div>
                          <div className="progress-bar rd_4 w-100">
                            <div
                              className="h-100 rd_4 bg_thm"
                              style={{ width: `${item.value}%` }}
                            />
                          </div>
                          <span className="Source_Regular">{item.count}</span>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>

                <div className='Rubik-Medium text-start1 w-100 mt-3 mb-2'>Reviews</div>
              </>
            )}
            <Row className='w-100 mt-2 d-flex justify-content-between flex-wrap'>
              {list2 && list2?.map((item, i) => {
                const user = item?.userId
                const fName = user?.first_name || ''
                const lName = user?.last_name || ''

                const date = dateFormat(item?.updatedAt, 'dmy')


                return (
                  <div className='bg-white w_49 w-xs-auto rd_4 p-3 bg-warning1 mb-4'>
                    {/* <div className='bg-warnin2g'> */}
                    <div className='d-flex align-items-center '>
                      <img src={`/icons/user.png`} alt='User Image' className='img-fluid mty-image hw_35px mr_10' />
                      <div className='w-100'>
                        <div className='d-flex align-items-center'>
                          <div className='Rubik-Medium fs_15 flex-grow-1'>{fName} {lName} <p className='m-0 Source_Regular fs_13 text-secondary d-inline-block'>({user?.countryId?.name})</p></div>
                          <div className='Source_Regular fs_14 text-end text-secondary d-none-on-xs'>{date}</div>
                        </div>
                        <div className='d-flex align-items-center bg1-warning mt-1'>
                          <RatingStars rating={item.rating} />
                          {/* <div className='Rubik-Regular bg1-success fs_13 text-secondary ms-2'>
                          {item.rating}
                        </div> */}
                        </div>
                      </div>
                    </div>

                    <div className='border_gray bg-light p-2 mt-3 rd_6'>
                      <div className='Source_Regular fs_14'>{item.message}</div>
                    </div>
                    <div className='Source_Regular fs_14 mt-2 text-end text-secondary d-show-on-xs'>{date}</div>
                  </div>
                )
              })}

              {(!isLoading && list2 == undefined || list2?.length == 0) && (
                <div className={`tab-pane bg-warning1 mb-2 mt-5 d-flex align-items-center flex-column`}>
                  <img src='/images/not_data.webp' alt='Reviews not found' className='img-fluid h_180px' />
                  <p className='Source_Medium text_gray ms-5 mr_10'>Reviews not found</p>
                </div>
              )}
            </Row>
          </>
        ) : (
          <Row className='bg-white rd_4 p-3 w-100 mt-2'>
            {/* {(!isLoading && hotels == undefined || vehicle?.length == 0) && (
                <div className='d_flex_align_justify_center flex-column mt-5'>
                  <img src='/icons/not-found.png' alt='not found' className='img-fluid h_100px w_100px mt-5' />
                  <h5 className='Source_SemiBold mt-4'>no data found</h5>
                </div>
              )} */}
          </Row>
        )}
      </div>


      <FeedbackModal isVisible={open3} onClose={setOpen3} type={'vendor'} id={vendor && vendor[0]?._id} vendorId={vendor[0]?._id} callback={setHideBtn} />

      <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

      <ShareModal url={url} isVisible={isModalOpen2} onClose={setIsModalOpen2} />

    </div>
  )
}

export default VendorDetails